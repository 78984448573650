import React from "react";
import bg from "../assets/img/bg.png";

const Navbar = () => {
  return (
    <nav>
      <img src={bg} alt="bg" />
    </nav>
  );
};

export default Navbar;
